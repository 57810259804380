<template>
  <loading-spinner v-if="loading" position="absolute"></loading-spinner>
  <div v-else class="main-container">
    <p class="section-title">يمكنك هنا أن تنتقل من صفحة إلى أخرى لتتحكم بأي منها</p>
    <div class="pages-cards">
      <div class="m-card rb" v-for="page in pages" :key="page.id">
        <div class="card-info rb-top">
          <img class="page-image rb" :src="page.picture" />
        </div>
        <div class="page-name">
          {{ page.name | nameReducer }}
        </div>
        <div class="card-action rb-bottom">
          <button
            :disabled="page.id === $store.getters['page/id']"
            class="rounded pointer m-select"
            @click="selectPage(page)"
          >
            <font-awesome-icon icon="bolt" />اختر
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      pages: []
    };
  },
  methods: {
    selectPage(page) {
      if (page.id === this.$store.getters["page/id"]) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "ًهذه الصفحة مفعّلة حاليا",
          position: "is-top"
        });
      } else {
        const context = this;
        this.loading = true;
        this.$api
          .customRequest({
            url: `/services/manage/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/selected-page`,
            method: "post",
            data: {
              selectedPage: page.id
            }
          })
          .then(() => {
            return this.$store
              .dispatch("page/setPage", page)
              .then(() => {
                this.$router.push({ name: "Commenter.NewCommenter" });
                this.loading = false;
                this.$buefy.toast.open({
                  duration: 3000,
                  message: page.name + " تم اختيار",
                  position: "is-top",
                  type: "is-success"
                });
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$buefy.toast.open({
              duration: 3000,
              message: "حدث خطأ أثناء اختيار الصفحة",
              position: "is-top",
              type: "is-danger"
            });
          });
      }
    },
    beforeDelete(page) {
      const selectedPage = page;
      this.$buefy.dialog.confirm({
        title: "إيقاف خدمات مجيب عن هذه الصفحة",
        message: "هل أنت متأكد من قرارك بإزالة خدمات مجيب عن هذه الصفحة؟",
        confirmText: "احذف خدمات مجيب",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deletePage(selectedPage)
      });
    },
    deletePage(selectedPage) {
      if (this.pages.length > 1) {
        /**
         * 1- set loading to true
         * 2- request: delete the page from the user and delete its data from pages node
         * 3- delete the page from
         * 4- select any other page from pages property of this component
         * 5- notify the user with the newly selected page
         */
        this.loading = true;
        this.makeDeletionRequest(selectedPage.id)
          .then(({ data }) => {
            this.loading = false;
            // Get the index of the selected page
            const indexOfSelectedPage = this.pages.reduce((itemIndex, item, index) => {
              if (item.id === selectedPage.id) {
                return index;
              }
              return itemIndex;
            }, -1);
            // Remove the page from pages
            if (indexOfSelectedPage !== -1) {
              this.pages.splice(indexOfSelectedPage, 1);
            }
            // SELECT a new page
            this.$store.commit("setCurrentPage", this.pages[0]);
            // route the user to Commenter view
            this.$router.push({ name: "Commenter.NewCommenter" });
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
      } else if (this.pages.length === 1) {
        /**
         * 1- set loading to true
         * 2- request: delete the page from the user and delete its data from pages node
         * 3- delete the page from pages property of this component
         * 4- select any other page from pages property of this component
         * 5- notify the user with the newly selected page
         */
        this.loading = true;
        this.makeDeletionRequest(selectedPage.id)
          .then(({ data }) => {
            this.loading = false;
            this.$auth0.logout(false);
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    makeDeletionRequest(id) {
      const options = {
        url: `/users/${this.$store.getters["user/id"]}/pages/${id}`,
        method: "delete",
        responseType: "json"
      };
      return this.$api.customRequest(options);
    }
  },
  mounted() {
    this.loading = true;
    const options = {
      timeout: 60000,
      url: `/services/manage/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/registered-pages`,
      method: "get",
      responseType: "json"
    };
    this.$api
      .customRequest(options)
      .then(({ data }) => {
        this.loading = false;
        this.pages = data;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
  },
  filters: {
    nameReducer: function(value) {
      if (value.length > 20) {
        return value.substring(0, 20) + "...";
      }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/_variables";

.main-container {
  text-align: center;
}

.main-container .section-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 60px;
}

.pages-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.rb {
  border-radius: 5px;
}

.rounded {
  border-radius: 50px;
}

.rb-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.rb-bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.section-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-card {
  background-color: white;
  width: 220px;
  padding: 10px;
  height: 300px;
  margin: 25px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.24);
  transition: all 0.3s ease-in-out;
}

.card-info {
  height: 75%;
  width: 100%;
}

.page-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.page-name {
  height: 30%;
  font-family: cairo;
  font-size: 17.5px;
}

.card-action button {
  border: 0;
  background-color: $success;
  color: white;
  font-size: 13px;
  line-height: 13px;
  padding: 0px 20px;
}

button.pointer {
  cursor: pointer;
}

.main-container {
  .m-card {
    padding: 16px;

    .card-action {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      height: 18%;

      button {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 14px;

        &.m-delete {
          background: $danger;
          &:hover {
            box-shadow: 0 0 10px rgba(255, 67, 67, 0.507);
          }
        }
        &.m-select {
          background: $success;
          &:hover {
            box-shadow: 0 0 10px #23d1607e;
          }
          &:disabled {
            background: gray;
            color: white;
            &:hover {
              box-shadow: none;
            }
          }
        }

        i {
          margin: 0;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
